import React, { Suspense, useMemo } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import * as layoutConstants from "../constants/layout";

// import { AllRoutes } from "./index";

import Root from "./Root";
import PrivateRoute from "./PrivateRoute";
// All layouts/containers
import DefaultLayout from "../layouts/Default";
import VerticalLayout from "../layouts/Vertical";
import DetachedLayout from "../layouts/Detached";
import HorizontalLayout from "../layouts/Horizontal";
import FullLayout from "../layouts/Full";
import Dashboard from "../pages/dashboard";
import { UserSessionProvider } from "../context/UserSessionContext";
import { useSelector } from "react-redux";
import DuaLayoutPage from "pages/duas/layout";
import { customerDataLoaderOptions } from "pages/control-panel/Customers/new";

// auth
const Login = React.lazy(() => import("../pages/account/Login"));
const TwoFactorAuth = React.lazy(() =>
  import("../pages/account/TwoFactorAuth")
);

const Logout = React.lazy(() => import("../pages/account/Logout"));
const Register = React.lazy(() => import("../pages/account/Register"));
const Confirm = React.lazy(() => import("../pages/account/Confirm"));
const ChangePassword = React.lazy(() =>
  import("../pages/account/ChangePassword")
);
const ForgetPassword = React.lazy(() =>
  import("../pages/account/ForgetPassword")
);
const LockScreen = React.lazy(() => import("../pages/account/LockScreen"));
const Landing = React.lazy(() => import("../pages/landing"));

// pages
const MyAccount = React.lazy(() => import("../pages/profile/MyAccount"));

// Pages - Control Panel
const ControlPanelPage = React.lazy(() =>
  import("../pages/control-panel/index")
);
const CustomsPage = React.lazy(() => import("../pages/control-panel/Customs"));
const ModalitiesPage = React.lazy(() =>
  import("../pages/control-panel/Modalities")
);

// General
const UsersPage = React.lazy(() => import("../pages/control-panel/Users"));
const NewUserPage = React.lazy(() =>
  import("../pages/control-panel/Users/new")
);
const EditUserPage = React.lazy(() =>
  import("../pages/control-panel/Users/edit")
);
const ViewUserPage = React.lazy(() =>
  import("../pages/control-panel/Users/view")
);

const CustomersPage = React.lazy(() =>
  import("../pages/control-panel/Customers")
);
const NewCustomerPage = React.lazy(() =>
  import("../pages/control-panel/Customers/new")
);

const ExchangeRatesPage = React.lazy(() =>
  import("../pages/control-panel/ExchangeRates")
);

// Catalogs
const RegimesPage = React.lazy(() => import("../pages/control-panel/Regimes"));
const CountriesPage = React.lazy(() =>
  import("../pages/control-panel/Countries")
);
const PortsPages = React.lazy(() => import("../pages/control-panel/Ports"));
const GeneralCodesPage = React.lazy(() =>
  import("../pages/control-panel/GeneralCodes")
);
const LocationsPage = React.lazy(() =>
  import("../pages/control-panel/Locations")
);
const ReleasesPage = React.lazy(() =>
  import("../pages/control-panel/Releases")
);
const TariffItemsPage = React.lazy(() =>
  import("../pages/control-panel/TariffItems")
);
const CurrenciesPage = React.lazy(() =>
  import("../pages/control-panel/Currencies")
);
const BanksPage = React.lazy(() => import("../pages/control-panel/Banks"));
const CentralAmericaCustomsPage = React.lazy(() =>
  import("../pages/control-panel/CentralAmericaCustoms")
);
const TaxesPage = React.lazy(() => import("../pages/control-panel/Taxes"));
const NewTaxPage = React.lazy(() => import("../pages/control-panel/Taxes/new"));
const EditTaxPage = React.lazy(() => import("../pages/control-panel/Taxes/edit"));

const TaxNotesPage = React.lazy(() => import("../pages/control-panel/TaxNotes"));
const NewTaxNotePage = React.lazy(() => import("../pages/control-panel/TaxNotes/new"));
const EditTaxNotePage = React.lazy(() => import("../pages/control-panel/TaxNotes/edit"));

const ErrorPageNotFound = React.lazy(() =>
  import("../pages/error/PageNotFound")
);
// const ErrorPageNotFoundAlt = React.lazy(() => import('../pages/error/PageNotFoundAlt'));
const ServerError = React.lazy(() => import("../pages/error/ServerError"));

// - other
// const Invoice = React.lazy(() => import('../pages/other/Invoice'));
// const FAQ = React.lazy(() => import('../pages/other/FAQ'));
// const Pricing = React.lazy(() => import('../pages/other/Pricing'));
const Maintenance = React.lazy(() => import("../pages/other/Maintenance"));

// App Pages

// DUAS
// const DuaPage = React.lazy(() => import("../pages/duas"));

const ImportsPage = React.lazy(() => import("../pages/duas"));
const NewImportPage = React.lazy(() => import("../pages/duas/new"));
const EditImportPage = React.lazy(() => import("../pages/duas/edit"));
const ViewImportPage = React.lazy(() => import("../pages/duas/view"));
const InvoicesPage = React.lazy(() => import("../pages/duas/invoices"));
const NewInvoicePage = React.lazy(() => import("../pages/duas/invoices/new"));
const EditInvoicePage = React.lazy(() => import("../pages/duas/invoices/edit"));

const LinesPage = React.lazy(() => import("../pages/duas/lines"));
const DocumentsPage = React.lazy(() => import("../pages/duas/documents"));
const ValueStatementPage = React.lazy(() =>
  import("../pages/duas/valueStatement")
);

// Apps
const TariffsPage = React.lazy(() => import("../pages/apps/Tariffs"));

const loading = () => <div className="">loading</div>;

const LoadComponent = ({ component: Component }) => (
  <Suspense fallback={loading()}>
    <Component />
  </Suspense>
);

const Routes = () => {
  const selector = useMemo(
    () => (state) => ({
      layout: state.Layout,
    }),
    []
  );

  const { layout } = useSelector(selector);

  const getLayout = () => {
    let layoutCls = VerticalLayout;

    switch (layout.layoutType) {
      case layoutConstants.LAYOUT_HORIZONTAL:
        layoutCls = HorizontalLayout;
        break;
      case layoutConstants.LAYOUT_DETACHED:
        layoutCls = DetachedLayout;
        break;
      case layoutConstants.LAYOUT_FULL:
        layoutCls = FullLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };
  let Layout = getLayout();
  const router = createBrowserRouter([
    { path: "/", element: <Root /> },
    {
      // public routes
      path: "/",
      element: (
        <UserSessionProvider>
          <DefaultLayout />
        </UserSessionProvider>
      ),
      children: [
        {
          path: "account",
          children: [
            { path: "login", element: <LoadComponent component={Login} /> },
            {
              path: "two-factor-authentication",
              element: <LoadComponent component={TwoFactorAuth} />,
            },
            {
              path: "register/:token",
              element: <LoadComponent component={Register} />,
            },
            {
              path: "confirm/:token",
              element: <LoadComponent component={Confirm} />,
            },
            {
              path: "reset-password/:token",
              element: <LoadComponent component={ChangePassword} />,
            },
            {
              path: "forget-password",
              element: <LoadComponent component={ForgetPassword} />,
            },
            {
              path: "lock-screen",
              element: <LoadComponent component={LockScreen} />,
            },
            { path: "logout", element: <LoadComponent component={Logout} /> },
          ],
        },
        {
          path: "error-404",
          element: <LoadComponent component={ErrorPageNotFound} />,
        },
        {
          path: "error-500",
          element: <LoadComponent component={ServerError} />,
        },
        {
          path: "maintenance",
          element: <LoadComponent component={Maintenance} />,
        },
        {
          path: "landing",
          element: <LoadComponent component={Landing} />,
        },
      ],
    },
    {
      // auth protected routes
      path: "/",
      element: (
        <UserSessionProvider>
          <PrivateRoute roles={"Admin"} component={Layout} />
        </UserSessionProvider>
      ),
      children: [
        {
          path: "dashboard",
          element: <LoadComponent component={Dashboard} />,
        },
        {
          path: "my-account",
          element: <LoadComponent component={MyAccount} />,
        },
        {
          path: "duas",
          element: <DuaLayoutPage />,
          children: [
            {
              path: ":duaType",
              element: <LoadComponent component={ImportsPage} />,
            },
            {
              path: ":duaType/new",
              element: <LoadComponent component={NewImportPage} />,
            },
            {
              path: ":duaType/:id/edit",
              element: <LoadComponent component={EditImportPage} />,
            },
            {
              path: ":duaType/:id",
              element: <LoadComponent component={ViewImportPage} />,
              children: [
                {
                  path: "invoices",
                  element: <LoadComponent component={InvoicesPage} />,
                  children: [
                    {
                      path: "new",
                      element: <LoadComponent component={NewInvoicePage} />,
                    },
                    {
                      path: ":invoiceId",
                      element: <LoadComponent component={EditInvoicePage} />,
                    },
                  ],
                },
                {
                  path: "lines",
                  element: <LoadComponent component={LinesPage} />,
                },
                {
                  path: "documents",
                  element: <LoadComponent component={DocumentsPage} />,
                },
                {
                  path: "value-statement",
                  element: <LoadComponent component={ValueStatementPage} />,
                },
              ],
            },
          ],
        },
        {
          path: "apps",
          children: [
            {
              path: "tariffs",
              element: <LoadComponent component={TariffsPage} />,
            },
          ],
        },
        {
          path: "control-panel",
          children: [
            {
              path: "home",
              element: <LoadComponent component={ControlPanelPage} />,
            },
            {
              path: "users",
              element: <LoadComponent component={UsersPage} />,
            },
            {
              path: "users/new",
              element: <LoadComponent component={NewUserPage} />,
            },
            {
              path: "users/:id",
              element: <LoadComponent component={EditUserPage} />,
            },
            {
              path: "users/:id/view",
              element: <LoadComponent component={ViewUserPage} />,
            },
            {
              path: "customers",
              element: <LoadComponent component={CustomersPage} />,
            },
            {
              path: "customers/new",
              element: <LoadComponent component={NewCustomerPage} />,
              loader: customerDataLoaderOptions,
            },
            {
              path: "exchange-rates",
              element: <LoadComponent component={ExchangeRatesPage} />,
            },
            {
              path: "regimes",
              element: <LoadComponent component={RegimesPage} />,
            },
            {
              path: "modalities",
              element: <LoadComponent component={ModalitiesPage} />,
            },
            {
              path: "currencies",
              element: <LoadComponent component={CurrenciesPage} />,
            },
            {
              path: "customs",
              element: <LoadComponent component={CustomsPage} />,
            },
            {
              path: "countries",
              element: <LoadComponent component={CountriesPage} />,
            },
            {
              path: "ports",
              element: <LoadComponent component={PortsPages} />,
            },
            {
              path: "general-codes",
              element: <LoadComponent component={GeneralCodesPage} />,
            },
            {
              path: "locations",
              element: <LoadComponent component={LocationsPage} />,
            },
            {
              path: "tariff-items",
              element: <LoadComponent component={TariffItemsPage} />,
            },
            {
              path: "releases",
              element: <LoadComponent component={ReleasesPage} />,
            },
            {
              path: "banks",
              element: <LoadComponent component={BanksPage} />,
            },
            {
              path: "central-america-customs",
              element: <LoadComponent component={CentralAmericaCustomsPage} />,
            },
            {
              path: "taxes",
              element: <LoadComponent component={TaxesPage} />,
            },
            {
              path: "taxes/new",
              element: <LoadComponent component={NewTaxPage} />,
            },
            {
              path: "taxes/:id",
              element: <LoadComponent component={EditTaxPage} />,
            },
            {
              path: "tax-notes",
              element: <LoadComponent component={TaxNotesPage} />,
            },
            {
              path: "tax-notes/new",
              element: <LoadComponent component={NewTaxNotePage} />,
            },
            {
              path: "tax-notes/:id",
              element: <LoadComponent component={EditTaxNotePage} />,
            }
          ],
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
};

export default Routes;
